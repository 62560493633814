import React from "react";
import Card from "../Card";

export default ({
  T,
  appStyles,
  isChargeCardDeselected,
  chargeCardBalance,
  currencySymbol,
  businessName,
  canUserLoadChargeCard,
  setChargeCardDeselected,
}) => {
  return (
    <Card appStyles={appStyles}>
      <Card.Title appStyles={appStyles}>
        {canUserLoadChargeCard
          ? T("Charge Card Discount")
          : `${businessName} ${T("credit")}`}
      </Card.Title>
      <Card.Content
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Card.Content.RadioGroup
          value={isChargeCardDeselected ? -1 : 1}
          onChange={(selection) => setChargeCardDeselected(selection === -1)}
          appStyles={appStyles}
        >
          <Card.Content.RadioOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.accentColor}
            value={1}
          >
            <span>
              {(canUserLoadChargeCard
                ? `${T("Charge card - balance left")}:`
                : `${businessName} ${T("Credit")} - ${T("balance left")}:`) +
                ` ${currencySymbol}${chargeCardBalance.toFixed(2)}`}
            </span>
          </Card.Content.RadioOption>
          <Card.Content.RadioOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.accentColor}
            value={-1}
          >
            <span>
              {T(
                `${T("Pay without using your")} ${T(businessName)} ${T(
                  "Credit",
                )}`,
              )}
            </span>
          </Card.Content.RadioOption>
        </Card.Content.RadioGroup>
      </Card.Content>
    </Card>
  );
};
