import { get } from "lodash";
import React, { useCallback } from "react";
import { Button, Box } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

import useWebShare from "react-use-web-share";

export default function({ checkDetails, context }) {
  const {
    user,
    T,
    pageContext: { business },
  } = context;
  const { loading, isSupported, share } = useWebShare();

  const checkId = get(checkDetails, "check.id");

  const name = get(user, "userDetails.data.name");
  const title = `${T("You've got a shared check from")} ${name}`;
  const text = "Login or signup for rewards and discounts";
  const appBaseUrl = get(business, "links.webApp");
  const url =
    appBaseUrl && get(new URL(`/scan/?checkid=${checkId}`, appBaseUrl), "href");

  const handleShare = useCallback(() => {
    share({
      title,
      text,
      url,
    });
  });
  if (!checkId || !url) {
    return null;
  }
  ("");
  return loading ? (
    "loading"
  ) : isSupported ? (
    <Box sx={{ padding: 2, textAlign: "center" }}>
      <Button
        variant="contained"
        onClick={handleShare}
        color="primary"
        endIcon={<ShareIcon />}
      >
        {T("Share and Split the check with a friend")}
      </Button>
    </Box>
  ) : null;
}
