import React from "react";
import { noop } from "lodash";
import { makeStyles } from "../AppContainer/mui-theme";
import {
  SwipeableDrawer,
  AppBar,
  DialogContent,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default function SwipeableTemporaryDrawer({
  open,
  onClose = noop,
  onOpen = noop,
  title,
  anchor = "right",
  children = "",
  T,
}) {
  const { classes } = useStyles();

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            disabled
            aria-hidden="true"
            size="large" 
            //used to space equally
          />
          <Typography align="center" variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label={T("Close")}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent} dividers={true}>
        {children}
      </DialogContent>
    </SwipeableDrawer>
  );
}

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
    minWidth: 320,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: theme.spacing(0),
  },

  fullList: {
    width: "auto",
  },
}));
