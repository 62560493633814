import React, { useMemo } from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

export default function FullScreenDialog({
  open,
  onClose: handleClose,
  title,
  children,
  T,
  direction = "up",
}) {
  const { classes } = useStyles();
  const Transition = useMemo(
    () =>
      React.forwardRef(function Transition(props, ref) {
        return <Slide direction={direction} ref={ref} {...props} />;
      }),
    [direction],
  );
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              disabled
              aria-hidden="true"
              size="large" 
              //used to space equally
            />
            <Typography align="center" variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label={T("Close")}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent} dividers={true}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: theme.spacing(0),
  },
}));
