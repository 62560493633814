import { includes, startCase } from "lodash";

export const getExpirationDateString = (paymentMethod) =>
  `${
    paymentMethod.expirationMonth < 10
      ? "0" + paymentMethod.expirationMonth
      : paymentMethod.expirationMonth
  }/${paymentMethod.expirationYear}`;

export const humanReadablePaymentTypeString = (typeString) => {
  return typeString &&
    !includes(["spreedlytoast", "paymentsos", "spreedlypurchase"], typeString)
    ? startCase(typeString)
    : "Credit Card";
};
