import React, { useEffect } from "react";
import { filter, get, map, findIndex, isEmpty, min } from "lodash";
import Card from "../Card";
import Button from "../Button";
import { TextInput } from "../Inputs";
import { LOGIN_TYPES } from "../LoginView";
import { GIFT_CARD_FIELDS } from "../../utils/constants";
import GiftCardIcon from "../icons/GiftCard.svg";
import LockIcon from "../icons/LockIcon.svg";

export default function({
  loading,
  user,
  giftCardsToRedeem = [],
  T,
  setGiftCardsToRedeem = noop,
  appStyles,
  currencySymbol,
  onConnectGiftCard,
  addExternalGiftCardToAccount,
  totalExternalGiftCardRedeemAmount,
  amountDue,
  openAuthView,
  registerInput,
  enableValutecGiftCards,
}) {
  const giftCards = filter(
    get(user, "externalGiftCards.data.giftCards"),
    "balance",
  );

  const hasTotal = amountDue > 0;

  const checked = map(
    filter(giftCardsToRedeem, (giftCard) => get(giftCard, "redeemAmount") > 0),
    (giftCard) => get(giftCard, "cardNumber"),
  );

  const handleChange = (chosenOptions) => {
    const totalNotUsedOrderAmount =
      amountDue - totalExternalGiftCardRedeemAmount;

    const chosenGiftCards = map(
      filter(get(user, "externalGiftCards.data.giftCards"), (giftCard) => {
        return (
          findIndex(
            chosenOptions,
            (cardNumber) => cardNumber === giftCard.cardNumber,
          ) > -1
        );
      }),
      (giftCard) => {
        const stateGiftCard = filter(
          giftCardsToRedeem,
          (gcToRedeem) => get(gcToRedeem, "cardNumber") === giftCard.cardNumber,
        );
        // console.log(
        //   "Redeem amount",
        //   isEmpty(stateGiftCard)
        //     ? min([totalNotUsedOrderAmount, giftCard.balance])
        //     : giftCard.balance,
        // );
        return {
          cardNumber: giftCard.cardNumber,
          redeemAmount: isEmpty(stateGiftCard)
            ? min([totalNotUsedOrderAmount, giftCard.balance])
            : giftCard.balance,
          balance: giftCard.balance,
          cardPIN: giftCard.cardPIN,
        };
      },
    );
    console.log("chosenGiftCards", chosenGiftCards);
    setGiftCardsToRedeem(chosenGiftCards);
  };

  const chosenGiftCardIds = map(giftCardsToRedeem, "cardNumber");

  useEffect(() => {
    // let leftAmountToFill = amountDue;
    // console.log({ leftAmountToFill });
    // const nextSelectedGiftCards = [];
    // _.forEach(giftCardsToRedeem, (giftCard) => {
    //   const redeemAmount = min(leftAmountToFill, giftCard.balance);
    //   console.log({ redeemAmount });
    //   if (redeemAmount > 0) {
    //     leftAmountToFill -= redeemAmount;
    //     nextSelectedGiftCards.push({ ...giftCard, redeemAmount });
    //   }
    // });

    setGiftCardsToRedeem([]);
  }, [amountDue]);

  if (isEmpty(giftCards)) {
    return null;
  }

  return enableValutecGiftCards && !loading ? (
    <Card appStyles={appStyles}>
      <Card.Title
        light
        appStyles={appStyles}
        style={{
          width: "calc(100% - 20px)",
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 0,
        }}
      >
        {T("Gift Cards")}
      </Card.Title>
      {user.loggedIn && (
        <Card.Content>
          <small>
            <Card.Content.CheckboxGroup
              name={T("Gift Cards")}
              limit={undefined}
              checked={checked}
              onChange={handleChange}
              appStyles={appStyles}
            >
              {map(giftCards, (giftCard) => {
                const stateGiftCard = filter(
                  giftCardsToRedeem,
                  (gcToRedeem) =>
                    get(gcToRedeem, "cardNumber") === giftCard.cardNumber,
                );
                const totalNotUsedOrderAmount =
                  amountDue - totalExternalGiftCardRedeemAmount;
                return (
                  <Card.Content.CheckboxOption
                    iconSize={16}
                    iconInnerSize={16}
                    pointColor={appStyles.actionColor}
                    value={giftCard.cardNumber}
                    key={giftCard.cardNumber}
                    disabled={
                      !hasTotal ||
                      (isEmpty(stateGiftCard) && totalNotUsedOrderAmount == 0)
                    }
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        {T("Card Number")}: {_.get(giftCard, "cardNumber")}
                      </span>
                      {giftCardsToRedeem && !isEmpty(stateGiftCard) && (
                        <span>
                          {T("Use")} {currencySymbol}{" "}
                          {giftCardsToRedeem &&
                            get(stateGiftCard[0], "redeemAmount").toFixed(
                              2,
                            )}{" "}
                          from: {currencySymbol}
                          {get(giftCard, "balance")}
                        </span>
                      )}
                      {(isEmpty(giftCardsToRedeem) || isEmpty(stateGiftCard)) &&
                        totalNotUsedOrderAmount > 0 && (
                          <span>
                            {T("Balance:")} {currencySymbol}
                            {get(giftCard, "balance")}
                          </span>
                        )}
                    </span>
                  </Card.Content.CheckboxOption>
                );
              })}
            </Card.Content.CheckboxGroup>
          </small>

          {/* {((get(user, "addExternalGiftCardState") &&
            get(user, "addExternalGiftCardState.sending")) ||
            (get(user, "externalGiftCards") &&
              get(user, "externalGiftCards.sending"))) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Loader appStyles={appStyles} />
            </div>
          )}
          {!(
            (get(user, "addExternalGiftCardState") &&
              get(user, "addExternalGiftCardState.sending")) ||
            (get(user, "externalGiftCards") &&
              get(user, "externalGiftCards.sending"))
          ) && (
            <span>
              <small>
                {T(
                  "For an existing physical gift card, enter the card number and PIN (leave empty if there is no PIN) to connect the card to your account",
                )}
              </small>

              <small
                style={{
                  width: "100%",
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <TextInput
                  onValid={(value) => {
                    // setNewGiftCard({ ...newGiftCard, cardNumber: value })
                  }}
                  placeholder={T("Gift Card Number")}
                  refEl={registerInput(GIFT_CARD_FIELDS.GIFT_CARD_NUMBER)}
                  appStyles={appStyles}
                  iconComponent={GiftCardIcon}
                  validator={(input) => !_.isEmpty(input)}
                  errorMessage={T("Please enter a valid gift card number")}
                  style={{
                    //marginRight: "5px",
                    minWidth: "60%",
                  }}
                />

                <TextInput
                  onValid={(value) => {
                    // setNewGiftCard({ ...newGiftCard, cardPIN: value })
                  }}
                  refEl={registerInput(GIFT_CARD_FIELDS.GIFT_CARD_PIN)}
                  appStyles={appStyles}
                  iconComponent={LockIcon}
                  placeholder={T("Gift Card PIN")}
                  style={{
                    //marginLeft: "5px",
                    minWidth: "35%",
                  }}
                  noCheckmark
                />
              </small>

              <Button
                style={{
                  margin: 0,
                }}
                appStyles={appStyles}
                centered
                disabled
                onClick={() => {
                  onConnectGiftCard(addExternalGiftCardToAccount);
                }}
              >
                {T("Connect Gift Card")}
              </Button>
            </span>
          )} */}
        </Card.Content>
      )}
      {/* {!user.loggedIn && (
        <Card.Content>
          <small>
            {T(
              "For an existing physical gift card, click on the button below to signup and connect the card to your account",
            )}
          </small>
          <Button
            style={{
              margin: 0,
              marginTop: "10px",
            }}
            appStyles={appStyles}
            centered
            disabled
            onClick={() => {
              openAuthView(LOGIN_TYPES.GIFT_CARD);
            }}
          >
            {T("Signup and connect Gift Card")}
          </Button>
        </Card.Content>
      )} */}
    </Card>
  ) : null;
}
