import React from "react";
import _ from "lodash";
import PriceLine from "../PriceLine";
import Button from "../Button";

export default ({
  priceDetails,
  currencySymbol,
  T,
  businessName,
  shouldDisplayDeliveryTip,
  shouldDisplayServiceOptionTip,
  shouldDisplayOptionToChargeCard,
  appStyles,
  chargeCardDetails,
  rechargeCard,
  canUserLoadChargeCard,
  style
}) => {
  const { rtl, hideTax } = appStyles;
  const hasChargeCardDiscount =
    priceDetails.chargeCardDiscount > 0 &&
    priceDetails.chargeCardDiscount.toFixed(2) !== "0.00";
  const hasCardDiscountAndCanLoadCard =
    hasChargeCardDiscount && canUserLoadChargeCard;
  const userHasFullCardDiscount =
    priceDetails.chargeCardDiscount &&
    priceDetails.total === 0 &&
    priceDetails.tax
      ? priceDetails.subtotal + priceDetails.tax ===
        priceDetails.chargeCardDiscount
      : priceDetails.subtotal === priceDetails.chargeCardDiscount;

  return (
    <div style={style}>
      {_.isNumber(priceDetails.deliveryPrice) &&
        (priceDetails.deliveryPrice > 0 ||
          !_.isNumber(priceDetails.serviceCharge)) && (
          <PriceLine
            subtotal
            label={T("Delivery Fee")}
            value={
              priceDetails.deliveryPrice > 0
                ? priceDetails.deliveryPrice - (priceDetails.tipAmount || 0)
                : 0
            }
            currencySymbol={currencySymbol}
            rtl={rtl}
          />
        )}
      {_.isNumber(priceDetails.tipAmount) &&
        (shouldDisplayDeliveryTip || shouldDisplayServiceOptionTip) && (
          <PriceLine
            subtotal
            label={
              shouldDisplayDeliveryTip ? T("Delivery Tip") : T("Service Tip")
            }
            value={priceDetails.tipAmount}
            currencySymbol={currencySymbol}
            rtl={rtl}
          />
        )}
      {_.isNumber(priceDetails.serviceCharge) && (
        <PriceLine
          subtotal
          label={T("Service Charge")}
          value={priceDetails.serviceCharge}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {!_.isEqual(priceDetails.total, priceDetails.subtotal) && (
        <PriceLine
          subtotal
          label={hasCardDiscountAndCanLoadCard ? T("Total") : T("Subtotal")}
          value={priceDetails.subtotal}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {priceDetails.totalDiscountBeforeTax > 0 && (
        <PriceLine
          subtotal
          discount
          label={T("Rewards")}
          value={priceDetails.totalDiscountBeforeTax || 0}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {!hideTax && (
        <PriceLine
          subtotal
          label={T("Tax")}
          value={priceDetails.tax - (priceDetails.totalTaxOnDiscount || 0)}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}

      {hasChargeCardDiscount && (
        <PriceLine
          subtotal
          discount
          label={`${businessName} ${T("credit")}`}
          value={priceDetails.chargeCardDiscount}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {!(userHasFullCardDiscount && canUserLoadChargeCard) && (
        <PriceLine
          label={
            hasChargeCardDiscount
              ? canUserLoadChargeCard
                ? T("Card Balance Left")
                : T("Amount Due")
              : T("Total")
          }
          value={priceDetails.total}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {priceDetails.priceOnReceipt && (
        <PriceLine
          receiptPrice
          label={T("*Price on receipt after discount:")}
          value={priceDetails.priceOnReceipt}
          currencySymbol={currencySymbol}
          rtl={rtl}
        />
      )}
      {shouldDisplayOptionToChargeCard && (
        <div style={{ display: "flex", alignItems: "center", padding: 16 }}>
          <span
            style={appStyles.rtl ? { marginLeft: 16 } : { marginRight: 16 }}
          >
            {chargeCardDetails}
          </span>
          <Button appStyles={appStyles} onClick={rechargeCard} centered>
            {T("Recharge Card")}
          </Button>
        </div>
      )}
    </div>
  );
};
